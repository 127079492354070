import React, { useMemo } from 'react';
import { Box, Container, Flex, Text, Icon } from '@qga/roo-ui/components';
import PageBlock from 'components/PageBlock';
import { rem } from 'polished';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getPathName } from 'store/router/routerSelectors';
import { qantasBusinessRewards, qantasQff } from '@qga/roo-ui/logos';
import { DisclaimerItemLogo, DisclaimerText, DisclaimerLink, TripAdvisorLogo } from 'components/Footer/DisclaimerItems';
import { useDataLayer } from 'hooks/useDataLayer';
import { getCampaignTermsAndConditions } from 'store/campaign/campaignSelectors';
import { getTreatments } from 'store/split/splitSelectors';
import TravelInsuranceDisclaimer from './TravelInsuranceDisclaimer';
import {
  TRIP_ADVISOR_IMG_BRANDING_URL,
  QCOM_TERMS_AND_CONDITIONS_URL,
  POINTS_EARN_ENABLED,
  QFF_ACCOUNT_MANAGEMENT,
  ACCOUNT_MANAGEMENT_TYPES,
  VPP_TERMS_CONDITIONS,
  TRAVEL_INSURANCE_CROSS_SELL_ENABLED,
  AIRBNB_LANDING_PAGE_URL,
  HOTELS_URL,
  // HOLIDAYS_URL,
  HOTEL_FAQ_URL,
} from 'config';
import Markup from 'components/Markup';
import TreatmentToggle from 'components/TreatmentToggle';

interface DisclaimerSection {
  id: string;
  logo?: JSX.Element;
  items: (JSX.Element | null | boolean)[];
}

const Disclaimer = () => {
  const { emitInteractionEvent } = useDataLayer();
  const campaignTermsAndConditions = useSelector(getCampaignTermsAndConditions);
  const pathName = useSelector(getPathName);
  const isConfirmationPage = pathName?.startsWith('/bookings');
  const splitTreatments = useSelector(getTreatments);
  const hasNoLocalCampaignDisclaimer = isEmpty(splitTreatments?.blue_banner_message?.config?.termsAndConditions);

  const sections = useMemo(() => {
    const qffDisclaimer = (
      <DisclaimerText data-testid="qff-disclaimer-text">
        You must be a Qantas Frequent Flyer member to earn and redeem points. Membership and points are subject to the Qantas Frequent Flyer
        program terms and conditions.
      </DisclaimerText>
    );

    const qffTerms = (
      <DisclaimerText data-testid="qff-terms-text">
        Qantas Frequent Flyer members will earn 3 Qantas Points per A$1 spent unless otherwise specified, for hotel stays booked through
        qantas.com/hotels, except Classic Hotel Rewards and Airbnb bookings. Points Club members will earn 25% more Qantas Points, and
        Points Club Plus members will earn 50% more Qantas Points. Qantas Points will be credited to your account at least 8 weeks after
        check-out. Qantas Points can be earned on cash only purchases and the cash component of Points Plus Pay purchases on eligible
        bookings and will not be earned on cancelled or refunded bookings. Qantas Points may be earned by the member in whose name the
        booking is made. Members will not be able to earn points on additional charges paid to the accommodation provider for extras
        (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable).
      </DisclaimerText>
    );

    const qffDepositPay = (
      <DisclaimerText data-testid="qff-deposit-pay-text">
        Deposit Pay is available on selected properties that offer a free cancellation window that is 21 days or more in the future from the
        date of booking.
      </DisclaimerText>
    );

    const qffFaqs = (
      <DisclaimerText data-testid="qff-faqs-text">
        Visit our{' '}
        <DisclaimerLink
          data-testid="qff-faqs-link"
          href={HOTEL_FAQ_URL}
          onClick={() => emitInteractionEvent({ type: 'FAQ', value: 'View FAQ Page Link Selected' })}
        >
          {' '}
          FAQ page{' '}
        </DisclaimerLink>{' '}
        for more.
      </DisclaimerText>
    );

    const qffPointsPay = (
      <DisclaimerText data-testid="qff-points-pay-text">
        * Qantas Frequent Flyer members can redeem Qantas Points when booking hotel accommodation through qantas.com/hotels or holiday
        packages through qantas.com/holidays, using Points Plus Pay. Members cannot redeem points for additional charges paid to the hotel
        for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Points Plus Pay allows you
        to choose the number of Qantas Points you redeem above the specified minimum level of 5,000 and pay for the remainder of the booking
        value with an Accepted Payment Card (including VISA, MasterCard or American Express). Points Plus Pay is not available for Classic
        Hotel Rewards.{' '}
        <DisclaimerLink
          data-testid="pointsPlusPayViewFullTermsAndConditionsLink"
          href={`${HOTELS_URL}/hotels-and-airbnb-terms-and-conditions`}
          onClick={() =>
            emitInteractionEvent({ type: 'Points Plus Pay Conditions', value: 'View Full Terms and Conditions Link Selected' })
          }
        >
          View full terms and conditions here
        </DisclaimerLink>
      </DisclaimerText>
    );

    const qffBonusPoints = (
      <DisclaimerText data-testid="qff-bonus-points-text">
        ++ 500 bonus Qantas Points will be awarded to Qantas Frequent Flyer members who make their first Airbnb booking. Qantas Frequent
        Flyer members will earn 1 Qantas Point per A$1 value for all Airbnb stays booked through{' '}
        <DisclaimerLink
          data-testid="airbnbHomepageLink"
          href={AIRBNB_LANDING_PAGE_URL}
          onClick={() => emitInteractionEvent({ type: 'Terms and Conditions', value: 'AirBnb Selected' })}
        >
          qantas.com/airbnb
        </DisclaimerLink>
        .
      </DisclaimerText>
    );

    const savingsDisclaimer = (
      <DisclaimerText data-testid="savings-disclaimer-text">
        ~ Saving is off the hotel&apos;s generally available rate for the same property, room type, days, inclusions and conditions.
      </DisclaimerText>
    );

    const qffTravelPass = (
      <TreatmentToggle split="qantas_travel_pass" treatment={['on']}>
        <Flex mt={2} data-testid="qta-pass-disclaimer">
          <Box fontSize={rem(10)} position="relative" mr={1} top={rem(2)}>
            ∇
          </Box>
          <DisclaimerText data-testid="qta-pass-disclaimer">
            Please note that your confirmation email will display any Qantas Pass, Qantas TravelPass, or Qantas Credit payment as
            &apos;Qantas Travel Pass&apos;.
          </DisclaimerText>
        </Flex>
      </TreatmentToggle>
    );

    const localCampaignDisclaimer = hasNoLocalCampaignDisclaimer && !campaignTermsAndConditions && (
      <TreatmentToggle split="blue_banner_message" treatment={'local_campaign_live'}>
        <DisclaimerText data-testid="local-campaign-disclaimer-alternative-text"></DisclaimerText>
      </TreatmentToggle>
    );

    const qbrDisclaimer = (
      <DisclaimerText data-testid="qbr-disclaimer-text">
        ** Businesses earn 1 Qantas Point per AU$1 spent on eligible Qantas Hotels worldwide. Eligible Qantas hotels excludes Classic Hotel
        Rewards. A business must be a Qantas Business Rewards Member to earn Qantas Points for your business. Qantas Points are not earned
        on any amounts payable directly to the hotel. &nbsp;
        <DisclaimerLink
          data-testid="businessRewardConditionApplyLink"
          href={QCOM_TERMS_AND_CONDITIONS_URL}
          onClick={() => emitInteractionEvent({ type: 'Business Rewards Conditions', value: 'Conditions Apply Link Selected' })}
        >
          Conditions apply
        </DisclaimerLink>
      </DisclaimerText>
    );

    const travelInsuranceTerms = TRAVEL_INSURANCE_CROSS_SELL_ENABLED ? (
      <TreatmentToggle split="travel_insurance_cross_sell_banner" treatment="on">
        {isConfirmationPage && (
          <Flex>
            <TravelInsuranceDisclaimer />
          </Flex>
        )}
      </TreatmentToggle>
    ) : null;

    const feesDisclaimer = (
      <DisclaimerText data-testid="fees-disclaimer-text">
        † Includes fee payable in local currency direct to hotel. Prices in AUD are approx and based on today&#39;s exchange rate.
      </DisclaimerText>
    );

    const descriptionAndRatingsDisclaimer = (
      <DisclaimerText data-testid="description-ratings-disclaimer-text">
        Descriptions and ratings featured are based on information provided by hotels and/or other service providers, distinguished
        accordingly. Ratings are either self-assigned by the service provider or obtained through Expedia (displayed as circles{' '}
        <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
        ), or by independent third parties (displayed as stars <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />) Any facilities shown as included are subject to change by the
        service provider. Qantas does not guarantee any particular standard or class of accommodation or other service provided by a third
        party.
      </DisclaimerText>
    );

    const tripAdvisorDisclaimer = (
      <Flex pt={4} flexWrap={'wrap'} gap={1} data-testid="trip-advisor-disclaimer-text">
        <DisclaimerText>Hotel ratings and reviews provided by</DisclaimerText>
        <TripAdvisorLogo src={TRIP_ADVISOR_IMG_BRANDING_URL} alt="Trip-Advisor Logo" role="img" aria-label="Trip-Advisor Logo" />
      </Flex>
    );

    const qffFinalTerms = (
      <DisclaimerText data-testid="qff-final-terms-text">
        {campaignTermsAndConditions && hasNoLocalCampaignDisclaimer ? (
          <Markup content={`^ ${campaignTermsAndConditions} &nbsp;`} />
        ) : (
          <>
            ^ {qffTerms} {qffDepositPay} {qffFaqs}
          </>
        )}
      </DisclaimerText>
    );

    const qffVppTerms = (
      <TreatmentToggle split="vpp" treatment="on">
        <DisclaimerText mt={2} data-testid="vpp-terms-and-conditions">
          {VPP_TERMS_CONDITIONS}
        </DisclaimerText>
      </TreatmentToggle>
    );

    const OneMillionPointsGiveawayTerms = (
      <TreatmentToggle split="hide_campaign_banner" treatment="on">
        <DisclaimerText mt={2} data-testid="one-million-points-terms-and-conditions">
          <Text fontWeight="bold" mr={1}>
            ‡ Promoter:
          </Text>
          Qantas Airways Limited.{' '}
          <Text fontWeight="bold" mr={1}>
            Eligible Entrants:
          </Text>{' '}
          Australian residents who are aged 18 years before the start of the Promotion Period, excluding directors, management, employees
          and their immediate families of the Promoter and Qantas Airways Limited and their related bodies corporate.{' '}
          <Text fontWeight="bold" mr={1}>
            Promotion Period:
          </Text>{' '}
          12:01am (AEST) 23 September 2024 - 11:59pm (AEST) on 27 September 2024.{' '}
          <Text fontWeight="bold" mr={1}>
            How to enter:
          </Text>{' '}
          Eligible Entrants will automatically receive an entry when they log into their Qantas Frequent Flyer account and book a Qantas
          Hotel or Qantas Holiday package from <DisclaimerLink href={'https://www.qantas.com/hotels'}>qantas.com/hotels</DisclaimerLink> or{' '}
          <DisclaimerLink href={'https://www.qantas.com/holidays'}>qantas.com/holidays</DisclaimerLink> with a minimum booking value of
          AU$100, and complete the stay between 24 September 2024 and 30 June 2025. Entrants can win more than once. Qantas Tours, Qantas
          Entertainment and Airbnb bookings are excluded.{' '}
          <Text fontWeight="bold" mr={1}>
            Prize:
          </Text>{' '}
          The total maximum notional prize pool value is AU$311,098, based on the recommended retail value at the time of printing. There
          will be two hundred and fifty (250) prizes to be won in total. The dispatch of the prizes will be credited to the prize winners’
          Qantas Frequent Flyer accounts up to eight (8) weeks from when: (a) if the winner completes their Eligible Stay during the
          Promotion Period, the date the winner is validated and has accepted the Major Prize; or (b) if the winner completes their Eligible
          Stay after the Promotion Period, the date the winner checks out of their Eligible Stay.{' '}
          <Text fontWeight="bold" mr={1}>
            Draw:{' '}
          </Text>
          10:00am (AEST) on 7 October 2024 at MDSA, Level 16, 1 Denison Street Street, North Sydney NSW 2060. Authorised under permit
          numbers: NSW Permit No.NTP/10916, ACT TP24/02013, SA Licence No. T24/1528. View full terms and conditions at{' '}
          <DisclaimerLink href={'https://www.qantas.com/hotels/campaigns/million-points-giveaway'}>
            qantas.com/hotels/campaigns/million-points-giveaway
          </DisclaimerLink>
        </DisclaimerText>
      </TreatmentToggle>
    );

    const result: DisclaimerSection[] = [];

    if (POINTS_EARN_ENABLED) {
      result.push({
        id: 'points-disclaimers',
        logo: <DisclaimerItemLogo src={qantasQff} alt="Qantas QFF Logo" role="img" aria-label="Qantas QFF Logo" />,
        items: [
          qffDisclaimer,
          qffFinalTerms,
          localCampaignDisclaimer,
          qffPointsPay,
          qffVppTerms,
          qffBonusPoints,
          savingsDisclaimer,
          qffTravelPass,
          OneMillionPointsGiveawayTerms,
        ],
      });

      result.push({
        id: 'business-rewards-disclaimers',
        logo: (
          <DisclaimerItemLogo
            src={qantasBusinessRewards}
            alt="Qantas Business Reward Logo"
            role="img"
            aria-label="Qantas Business Reward Logo"
          />
        ),
        items: [qbrDisclaimer],
      });
    } else {
      const items = [savingsDisclaimer];
      if (QFF_ACCOUNT_MANAGEMENT === ACCOUNT_MANAGEMENT_TYPES.CHECKOUT_ONLY) {
        items.unshift(qffVppTerms);
      }

      result.push({ id: 'nopoints-disclaimers', items });
    }

    result.push({
      id: 'misc-disclaimers',
      items: [travelInsuranceTerms, feesDisclaimer, descriptionAndRatingsDisclaimer, tripAdvisorDisclaimer],
    });

    return result;
  }, [campaignTermsAndConditions, emitInteractionEvent, isConfirmationPage, hasNoLocalCampaignDisclaimer]);

  return (
    <PageBlock py={15} bg="white">
      <Container data-print-style="hidden">
        <Text display="block" fontSize="base" fontWeight="bold" mb={3} mt={5}>
          Important information
        </Text>
        {sections.map((section) => (
          <Flex key={section.id} data-testid={section.id} flexDirection={['column', 'row']} alignItems="flex-start">
            {section.logo}
            <Box>
              {section.items.map((item, i) => (
                <Box key={i} mb={2}>
                  {item}
                </Box>
              ))}
            </Box>
          </Flex>
        ))}
      </Container>
    </PageBlock>
  );
};

export default Disclaimer;
